import { environment } from './../../environments/environment';

export class Global {
    public static Home_EndPoint = environment.apiurl + `api/Home/`;
    public static Tokan_EndPoint =   environment.apiurl +`api/Tokan/`;
    public static UtilapiUrl= environment.apiurl;
}
export class swalGlobal {
    public static successResponseText = 'Done!';
    public static confirmButtonText = 'Yes';
    public static confirmButtonColor = '#3085d6';
    public static cancelButtonText = 'No';
    public static cancelButtonColor = '#d33';
    public static title = 'Are you sure?';
}
