import { Component, OnInit, Input, ViewChild, SimpleChange } from '@angular/core';
import { SwiperDirective, SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper'; 
import { Property } from '../../app.models';
import { Settings, AppSettings } from '../../app.settings';

import { AppService } from '../../app.service'; 
import { CompareOverviewComponent } from '../compare-overview/compare-overview.component'; 

@Component({
  selector: 'app-property-item',
  templateUrl: './property-item.component.html',
  styleUrls: ['./property-item.component.scss'] 
})
export class PropertyItemComponent implements OnInit {
  PropertyItemLink = "http://www.sworldproperties.com/properties/"
  @Input() property: Property;
  @Input() viewType: string = "grid";
  @Input() viewColChanged: boolean = false; 
  @Input() fullWidthPage: boolean = true;   
  public column:number = 4;
  // public address:string; 
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  public config: SwiperConfigInterface = {};
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  public settings: Settings;
  loadAPI: Promise<any>;
  public defaultimage="assets/images/props/defualt/defualtprop.jpeg";
  constructor(public appSettings:AppSettings, public appService:AppService) {
    this.settings = this.appSettings.settings;
    this.loadAPI = new Promise((resolve) => {
      console.log('resolving promise...');
      this.loadScript();
  });
  }
  show(id:any){
    $(".popup_box." + id).toggleClass("show");
  }
  whatsapp(title:any){
    this.PropertyItemLink = this.PropertyItemLink + title;
    const link = encodeURI(this.PropertyItemLink);
    //const location = `https://web.whatsapp.com/send?text=${link}`;
    var location = '';
    //const msg = encodeURIComponent('Hey, I found this article');
    var width = $(window).width();
    if (width <= 480) {
      location = `whatsapp://send?text=${link}`;
    } else {
      location = `https://web.whatsapp.com/send?text=${link}`;
    }
    $(".whatsapp").attr("href",encodeURI(location));
  }
  facebook(title:any){
    this.PropertyItemLink = this.PropertyItemLink + title;
    const link = encodeURI(this.PropertyItemLink);
    const location = `https://www.facebook.com/share.php?u=${link}`;
    $(".facebook").attr("href",encodeURI(location));
  }
  twitter(title:any){
    this.PropertyItemLink = this.PropertyItemLink + title;
    const link = encodeURI(this.PropertyItemLink);
    // const msg = encodeURIComponent('Hey, I found this article');
    // this.PropertyItemLink = `http://twitter.com/share?&url=${link}&text=${msg}&hashtags=javascript,programming`;
    const location = `http://twitter.com/share?&url=${link}&hashtags=SWorldProperites`;
    $(".twitter").attr("href",encodeURI(location));
    }
  telegram(title:any){
    this.PropertyItemLink = this.PropertyItemLink + title;
    const link = encodeURI(this.PropertyItemLink);
    //const msg = encodeURIComponent('Hey, I found this article');
    const location = `https://telegram.me/share/url?url=${link}`;
    $(".telegram").attr("href",encodeURI(location));
  }
  linkedin(title:any){
    this.PropertyItemLink = this.PropertyItemLink + title;
    const link = encodeURI(this.PropertyItemLink);
    const location=`https://www.linkedin.com/sharing/share-offsite/?url=${link}`;
    $(".linkedin").attr("href",encodeURI(location));
  }
  ngOnInit() { }
  public loadScript() {
    const url = 'https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-6176736bcc42fc99';
    console.log('preparing to load...')
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    //node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
}
  ngAfterViewInit(){
    this.initCarousel();
    // this.appService.getAddress(this.property.location.lat, this.property.location.lng).subscribe(data=>{
    //   console.log(data['results'][0]['formatted_address']);
    //   this.address = data['results'][0]['formatted_address'];
    // })
  } 
 
  ngOnChanges(changes: {[propKey: string]: SimpleChange}){  
    if(changes.viewColChanged){
      this.getColumnCount(changes.viewColChanged.currentValue);
      if(!changes.viewColChanged.isFirstChange()){
        if(this.property.gallery.length > 1){     
           this.directiveRef.update();  
        } 
      }
    } 

    for (let propName in changes) {      
      // let changedProp = changes[propName];
      // if (!changedProp.isFirstChange()) {
      //   if(this.property.gallery.length > 1){
      //     this.initCarousel();
      //     this.config.autoHeight = true;       
      //     this.directiveRef.update();  
      //   }       
      // }      
    }  
  }

  public getColumnCount(value){
    if(value == 25){
      this.column = 4;
    }
    else if(value == 33.3){
      this.column = 3;
    }
    else if(value == 50){
      this.column = 2
    }
    else{
      this.column = 1;
    }
  }

  public getStatusBgColor(status){
    switch (status) {
      case 'For Sale':
        return '#558B2F';  
      case 'For Rent':
        return '#1E88E5'; 
      case 'Open House':
        return '#009688';
      case 'No Fees':
        return '#FFA000';
      case 'Hot Offer':
        return '#F44336';
      case 'Sold Out':
        return '#d41c1c';
      default: 
        return '#01579B';
    }
  }


  public initCarousel(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: false,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: true,
      preloadImages: false,
      lazy: true,  
      nested: true,
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false
      // },
      speed: 500,
      effect: "slide"
    }
  }
  

  public addToCompare(){
    this.appService.addToCompare(this.property, CompareOverviewComponent, (this.settings.rtl) ? 'rtl':'ltr'); 
  }

  public onCompare(){
    return this.appService.Data.compareList.filter(item=>item.id == this.property.id)[0];
  }

  public addToFavorites(){
    this.appService.addToFavorites(this.property, (this.settings.rtl) ? 'rtl':'ltr');
  }

  public onFavorites(){
    return this.appService.Data.favorites.filter(item=>item.id == this.property.id)[0];
  }


}
