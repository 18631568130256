import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { AppService } from 'src/app/app.service';
import { TokenService } from 'src/app/services/Token.service';
import { Global } from '../global';
import { EmbedVideoService } from 'ngx-embed-video';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit { 
  TestimonalItemLink = "http://www.sworldproperties.com/testimonials/";
  public testimonials;
  public videoLink;
  public config: SwiperConfigInterface = { };
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  constructor(public appService:AppService,public tokenservices:TokenService, private embedService: EmbedVideoService) { }

  ngOnInit() {
    this.getTestimonials(); 
  }
  show(id:any){
    $(".popup_box." + id).toggleClass("show");
  }
  whatsapp(title:any){
    this.TestimonalItemLink = this.TestimonalItemLink + title;
    const link = encodeURI(this.TestimonalItemLink);
    //const location = `https://web.whatsapp.com/send?text=${link}`;
    var location = '';
    //const msg = encodeURIComponent('Hey, I found this article');
    var width = $(window).width();
    if (width <= 480) {
      location = `whatsapp://send?text=${link}`;
    } else {
      location = `https://web.whatsapp.com/send?text=${link}`;
    }
    $(".whatsapp").attr("href",encodeURI(location));
  }
  facebook(title:any){
    this.TestimonalItemLink = this.TestimonalItemLink + title;
    const link = encodeURI(this.TestimonalItemLink);
    const location = `https://www.facebook.com/share.php?u=${link}`;
    $(".facebook").attr("href",encodeURI(location));
  }
  twitter(title:any){
    this.TestimonalItemLink = this.TestimonalItemLink + title;
    const link = encodeURI(this.TestimonalItemLink);
    // const msg = encodeURIComponent('Hey, I found this article');
    // this.TestimonalItemLink = `http://twitter.com/share?&url=${link}&text=${msg}&hashtags=javascript,programming`;
    const location = `http://twitter.com/share?&url=${link}`;
    $(".twitter").attr("href",encodeURI(location));
    }
  telegram(title:any){
    this.TestimonalItemLink = this.TestimonalItemLink + title;
    const link = encodeURI(this.TestimonalItemLink);
    //const msg = encodeURIComponent('Hey, I found this article');
    const location = `https://telegram.me/share/url?url=${link}`;
    $(".telegram").attr("href",encodeURI(location));
  }
  linkedin(title:any){
    this.TestimonalItemLink = this.TestimonalItemLink + title;
    const link = encodeURI(this.TestimonalItemLink);
    const location=`https://www.linkedin.com/sharing/share-offsite/?url=${link}`;
    $(".linkedin").attr("href",encodeURI(location));
  }
  getTestimonials(){
    this.tokenservices.loginValidate3(Global.Tokan_EndPoint+ 'GetTestimonials', null).subscribe
    ((response: any) => {      
         
  this.testimonials=response.filter(r => r.Rating == 5);
  //debugger;
  //this.transform(response[0].VideoLink)
  // const res = response.map(({VideoLink}) => ({VideoLink}));
  // for(var i in res){
    //this.sanitizer.bypassSecurityTrustResourceUrl(
    //  this.videoLink = res[i].VideoLink
      //alert(this.videoLink);
      //$("#test").attr('src',"https://www.google.com");
      //);
  //}
    },
    err => { console.log('Something went wrong!'); });
  }
 
  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 3,
      spaceBetween: 5,       
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,  
      // breakpoints: {
      //   480: {
      //     slidesPerView: 1
      //   },
      //   740: {
      //     slidesPerView: 2,
      //   },
      //   960: {
      //     slidesPerView: 3,
      //   }
      // }
      breakpoints: {
              300: {
           slidesPerView: 1
         },
         991: {
          slidesPerView: 3
        }
      }
    }
  }
  getimagelink(url:string) {
var splitcount=url.split("/");
return "https://i.ytimg.com/vi/"+splitcount[(splitcount.length-1)]+"/hqdefault.jpg";
  }
  VideoItem(url:string){
    window.open(url, "_blank");
  }
}
