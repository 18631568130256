import { Menu } from './menu.model';

export const horizontalMenuItems = [ 
    new Menu (1, 'HOME', '/', null, null, false, 0),
    new Menu (70, 'ABOUT US', null, null, null, true, 0),  
    new Menu (2, 'PROPERTIES', '/properties', null, null, false, 0), 
    new Menu (201, 'A TO Z SERVICES', '/articles', null, null, false, 0), 
    new Menu (202, 'TESTMONIALS', '/testmonialslist', null, null, false, 0), 
    //new Menu (40, 'PAGES', null, null, null, true, 0),
    // new Menu (41, 'AGENTS', '/agents', null, null, false, 40), 
    // new Menu (42, 'AGENT', '/agents/1', null, null, false, 40),
    // new Menu (43, 'LOGIN', '/login', null, null, false, 40), 
    // new Menu (44, 'REGISTER', '/register', null, null, false, 40), 
    // new Menu (45, 'FAQs', '/faq', null, null, false, 40),
    // new Menu (46, 'PRICING', '/pricing', null, null, false, 40), 
    // new Menu (47, 'TERMS_CONDITIONS', '/terms-conditions', null, null, false, 40), 
    // new Menu (48, 'Landing', '/landing', null, null, false, 40),  
    // new Menu (50, '404 Page', '/404', null, null, false, 40),  
    new Menu (60, 'CONTACT', '/contact', null, null, false, 0),   
   // new Menu (202, 'TESTMONIAL DETAILS', '/testmonialdetails', null, null, false, 0), 
    new Menu (140, 'Others', null, null, null, true, 40),
    new Menu (141, 'EXTERNAL_LINK', null, 'http://themeseason.com', '_blank', false, 140), 
    new Menu (142, 'Menu item', null, 'http://themeseason.com', '_blank', false, 140),
    new Menu (143, 'Menu item', null,'http://themeseason.com', '_blank', false, 140),
    new Menu (144, 'Menu item', null,'http://themeseason.com', '_blank', false, 140),
    new Menu (71, 'About company', "/about",null, null, false, 70),    
    new Menu (72, 'Our Services', null, "/about/#ourservices_scroll", null, false, 70),   
    new Menu (73, 'Agent Info', null, "/about/#agentinfo_scroll", null, false, 70),   
    new Menu (74, 'Achievements', null, "/about/#achievemt_scroll", null, false, 70),      
    new Menu (75, 'Get in touch', null, "/about/#getintouch_scroll", null, false, 70)  
]

export const verticalMenuItems = [ 
    new Menu (1, 'HOME', '/', null, null, false, 0),
    new Menu (70, 'ABOUT US', null, null, null, true, 0),  
    new Menu (2, 'PROPERTIES', '/properties', null, null, false, 0), 
    new Menu (201, 'A TO Z SERVICES', '/articles', null, null, false, 0), 
    new Menu (202, 'TESTMONIALS', '/testmonialslist', null, null, false, 0), 
    //new Menu (40, 'PAGES', null, null, null, true, 0),
    // new Menu (41, 'AGENTS', '/agents', null, null, false, 40), 
    // new Menu (42, 'AGENT', '/agents/1', null, null, false, 40),
    // new Menu (43, 'LOGIN', '/login', null, null, false, 40), 
    // new Menu (44, 'REGISTER', '/register', null, null, false, 40), 
    // new Menu (45, 'FAQs', '/faq', null, null, false, 40),
    // new Menu (46, 'PRICING', '/pricing', null, null, false, 40), 
    // new Menu (47, 'TERMS_CONDITIONS', '/terms-conditions', null, null, false, 40), 
    // new Menu (48, 'Landing', '/landing', null, null, false, 40),  
    // new Menu (50, '404 Page', '/404', null, null, false, 40),  
    new Menu (60, 'CONTACT', '/contact', null, null, false, 0),  
    //new Menu (202, 'TESTMONIAL DETAILS', '/testmonialdetails', null, null, false, 0),  
    new Menu (140, 'Others', null, null, null, true, 40),
    new Menu (141, 'EXTERNAL_LINK', null, 'http://themeseason.com', '_blank', false, 140), 
    new Menu (142, 'Menu item', null, 'http://themeseason.com', '_blank', false, 140),
    new Menu (143, 'Menu item', null,'http://themeseason.com', '_blank', false, 140),
    new Menu (144, 'Menu item', null,'http://themeseason.com', '_blank', false, 140),
    new Menu (71, 'About company', "/about",null, null, false, 70),    
    new Menu (72, 'Our Services', "/about#ourservices_scroll", null, null, false, 70),   
    new Menu (73, 'Agent Info', "/about#agentinfo_scroll", null, null, false, 70),   
    new Menu (74, 'Achievements', "/about#achievemt_scroll", null, null, false, 70),      
    new Menu (75, 'Get in touch', "/about#getintouch_scroll", null, null, false, 70)  

]
