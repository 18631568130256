import { Injectable } from '@angular/core';
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Global } from '../shared/global';
import { ConnectionService } from 'ng-connection-service'; 

@Injectable()
export class TokenService {
  isConnected = true;  
  noInternetConnection: boolean;  
    constructor(private http: HttpClient
        , private router: Router
        , private jwtHelper: JwtHelperService
        , private connectionService: ConnectionService) {
          
             
             var parts = {
                "queryvars": {}
            }
            
            var sPageURL = window.location.search.substring(1);
            
                var sURLVariables = sPageURL.split(/[&||?]/);
                var absUrl = window.location.href;
                if(absUrl.indexOf("?")>0)
                {
                var elements = absUrl.split("?");
                if(elements!=null)
                {
                parts["queryString"] = elements[1];
                parts["requestParams"] = ((parts["queryString"].split("#"))[0]).split("&");
                parts["requestParams"].forEach(function (queryStringVariable) {
                    var __variable = queryStringVariable.split("=");
                    parts.queryvars[__variable[0]] = __variable[1];
                });
                if(parts.queryvars["code"]!=null)
                    localStorage.setItem("code", parts.queryvars["code"]);
                if(parts.queryvars["error"]!=null)
                    localStorage.setItem("error", parts.queryvars["error"] + ":" + parts.queryvars["error_description"]);
            }
            }
         }
    loginValidate(url: string, credentials: any) {
      const body = JSON.stringify(credentials);
      var token=this.aftoken();
      let tokenval;
        if(token==null)
         {
            tokenval='0'
         }
        else
         {
            tokenval=this.aftoken();    
          }
      const headers: HttpHeaders= new HttpHeaders()
      .append('Content-Type',  'application/json')
      .append('XSRF-TOKEN',  tokenval);
      const options = {
          headers: headers
        };
        
      return this.http.post(url, body, options).pipe(map((res :any)=> res),catchError((error, caught) => {
        //this.handleAuthError(error);
        
        if (error.status === 0 || error.status === 401  ) {
          localStorage.removeItem('currentUser');
          this.router.navigate([`authentication/login`]);
        }
        return Promise.reject(error.message || error);
      })
      );    
  }
  private handleError1(error: any): Promise<any> {
    // In a real world app, we might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.toString() || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return Promise.reject(error.message || error);
  }

   
    loginValidate2(url: string, credentials: any) {
      const body = JSON.stringify(credentials);
      const headers: HttpHeaders= new HttpHeaders()
      .append('Content-Type',  'application/json')
      .append('Authorization', 'Bearer ' + this.token())
      .append('XSRF-TOKEN',  localStorage.getItem('wstoken'));
      const options = {
          headers: headers
        };
      return this.http.post(url, body, options).pipe(map(res => res));
  }
  loginValidate3(url: string, credentials: any) {
    const body = JSON.stringify(credentials);
    const headers: HttpHeaders= new HttpHeaders()
    .append('Content-Type',  'application/json');
    // .append('Authorization', 'Bearer ' + this.token())
    // .append('XSRF-TOKEN',  localStorage.getItem('wstoken'));
    const options = {
        headers: headers
      };
    return this.http.post(url, body, options).pipe(map((res:any) => res));
}

isInternetactive()
 {
    this.connectionService.monitor().subscribe(isConnected => {  
      this.isConnected = isConnected.hasInternetAccess;  
      if (this.isConnected) {  
        this.noInternetConnection=false;  
        return true;
      }  
      else {  
        this.noInternetConnection=true;  
        return false;
      }  
    })  
  return this.isConnected;
 }
     isLoggedin() {
      
        // tslint:disable-next-line:prefer-const
        let token = localStorage.getItem('currentUser');
        if(token!="undefined" && token!=null){
        if (token != null && !this.jwtHelper.isTokenExpired(token)) { return true; }
        }
        return false;
    }

    token() {
        let token = localStorage.getItem('currentUser');
        return token;
    }

    aftoken() {
       
        let aftoken = localStorage.getItem('wstoken');
        return aftoken;
    }

    post(url: string, model: any, header?: Headers) {
    
      //if (this.tokanService.isLoggedin() === false) {
       // this.TokenExpirition();
     // }
  
      let body = JSON.stringify(model);
      const headers: HttpHeaders= new HttpHeaders()
      .append('Content-Type',  'application/json');
      const options = {
          headers: headers
        };
      // const headers: HttpHeaders = new HttpHeaders()
      //   .append('Content-Type', 'application/json')
      //   .append('Authorization', 'Bearer ' + this.tokanService.token())
      //   .append('XSRF-TOKEN', localStorage.getItem('wstoken'));
      // const options = {
      //   headers: headers
      // };
      return this.http.post(url, body, options).pipe(map((response: Response) => response), catchError((response, caught) => {
        if (response.status === 0 || response.status === 401) {
          localStorage.removeItem('currentUser');
          this.router.navigate([`#`]);
        }
        return  Promise.reject(response.message || response);
      }))
    };
   
}
