import { Component, OnInit } from '@angular/core';
import { PropertiesService } from 'src/app/services/properties.service';
import { Global } from '../global';

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.scss']
})
export class GetInTouchComponent implements OnInit {
  public getintouch:string;
  public achievement:string;
  constructor(public propservices:PropertiesService) { }

  ngOnInit() {
this.getintouchDetails()

  }
getintouchDetails(){
  this.propservices.post(Global.Home_EndPoint + 'getInTouch', null).subscribe((response: any) => {
    this.getintouch=response.getinTouch;
    this.achievement=response.achievement;
  });
}
}
