import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-properties-cityflag',
  templateUrl: './properties-cityflag.component.html',
  styleUrls: ['./properties-cityflag.component.scss']
})
export class PropertiesCityFlagComponent implements OnInit {
  @Input() variant:number = 1;
  @Input() searchOnBtnClick:boolean = true;
  public form: UntypedFormGroup;
  public btnsingclass:string="";
  public btnindclass:string="";
  public btnhongclass:string="";
  public btndubaiclass:string="";
  public Isindiacountry:boolean=false;
  public Issingaporecountry:boolean=false;
  public Ishongkongcountry:boolean=false;
  public Isdubaicountry:boolean=false;
  @Output() onSearchCityChangeflag: EventEmitter<any> = new EventEmitter<any>();
  @Input() removedSearchCityFieldFlag:boolean=false;
  public hydcount:number=0;
  public visakcount:number=0;
  public bancount:number=0;
  public singaporecount:number=0;
  public dubaicount:number=0;
  public Hongkongcount:number=0;
  constructor( public fb: UntypedFormBuilder,public appService:AppService) {this.getPropertiesforflag(); }

  ngOnInit(): void {

  var cdata=  window.location.href.split("=")[1];

    //var flagid=localStorage.getItem("countryid");
    if(cdata!=undefined && cdata!=null){
      //var cdata=flagid=="1"?"India":flagid=="2"?"Singapore":flagid=="3"?"Hong Kong":"Dubia";
     if(cdata.toLocaleLowerCase()=="india"){
        this.Isindiacountry=true;
         }
         else if(cdata.toLocaleLowerCase()=="singapore"){
          this.Issingaporecountry=true;
           }
           else if(cdata.toLocaleLowerCase()=="hong%20kong"){
            this.Ishongkongcountry=true;
             }
             else{
              this.Isdubaicountry=true;
               }
    }
    localStorage.removeItem("countryid");
    this.form = this.fb.group({
      countryflag:null
    }); 

  }

  public getAppearance(){
    return (this.variant != 3) ? 'outline' : '';
  }
  public getFloatLabel(){
    return (this.variant == 1) ? 'always' : '';
  }
  public singaporeimg(){
    localStorage.setItem('countryid',"2");
    this.btndubaiclass="";
    this.btnindclass="";
    this.btnhongclass="";
   this.btnsingclass="activeborder";
   var url =window.location.origin;
   window.location.href = url + "/properties?country=singapore";
  // this.onSearchCityChangeflag.emit("Singapore");
  }
  public hydimg(){
    localStorage.setItem('countryid',"1");
    localStorage.setItem('cityselected',"Hyderabad");
    this.btnsingclass="";
    this.btnhongclass="";
    this.btndubaiclass="";
   this.btnindclass="activeborder";
   var url = window.location.origin;
    window.location.href = url + "/properties?country=India&city=Hyderabad";
  // this.onSearchCityChangeflag.emit("Hyderabad");
  }
  public visakimg(){
    localStorage.setItem('countryid',"1");
    localStorage.setItem('cityselected',"Visakhapatnam");
    this.btnsingclass="";
    this.btnhongclass="";
    this.btndubaiclass="";
   this.btnindclass="activeborder";
   var url = window.location.origin;
   window.location.href = url + "/properties?country=India&city=Visakhapatnam";
  
   //this.onSearchCityChangeflag.emit("Visakhapatnam");
  }
  public bangimg(){
    localStorage.setItem('countryid',"1");
    localStorage.setItem('cityselected',"Bangalore");
    this.btnsingclass="";
    this.btnhongclass="";
    this.btndubaiclass="";
   this.btnindclass="activeborder";
   var url = window.location.origin;
   window.location.href = url + "/properties?country=India&city=Bangalore";
   //this.onSearchCityChangeflag.emit("Bangalore");
  }
  public hongimg(){
    localStorage.setItem('countryid',"3");
    this.btnsingclass="";
    this.btnindclass="";
    this.btndubaiclass="";
   this.btnhongclass="activeborder";
   var url = window.location.origin;
   window.location.href = url + "/properties?country=hong kong";
   //this.onSearchCityChangeflag.emit("Hong Kong");
  }
  public dubaiimg(){
    localStorage.setItem('countryid',"4");
    this.btnsingclass="";
    this.btnindclass="";
    this.btnhongclass="";
   this.btndubaiclass="activeborder";
   var url = window.location.origin;
   window.location.href = url + "/properties?country=dubia";
   //this.onSearchCityChangeflag.emit("Dubia");
  }
  ngOnChanges(){
    
    if(this.removedSearchCityFieldFlag){
      this.btnsingclass="";
      this.btnindclass="";
      this.btnhongclass="";
      this.btndubaiclass="";
      this.removedSearchCityFieldFlag=false;
    }
   }
   public getPropertiesforflag(){  
    this.appService.getProperties().subscribe(data => {      
      this.hydcount = data.filter(property => property.city == 'Hyderabad').length;
      this.visakcount = data.filter(property => property.city == 'Visakhapatnam').length;
      this.bancount = data.filter(property => property.city == 'Bangalore').length;
      this.singaporecount =data.filter(property => property.country == 'Singapore').length;
      this.Hongkongcount =data.filter(property => property.country == 'Hong Kong').length;
      this.dubaicount =data.filter(property => property.country == 'Dubia').length;
    });
  }
  
}
