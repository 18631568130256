import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Settings, AppSettings } from './app.settings';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Global } from './shared/global';
import { TokenService } from './services/Token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
   
  public settings: Settings;
  public value: any;
  public wstoken:any;
  public credentials={};
  constructor(public appSettings:AppSettings, 
              public router:Router, 
              @Inject(PLATFORM_ID) private platformId: Object,
              public translate: TranslateService,public tokenService: TokenService){
                // this.Gettoken();
    this.settings = this.appSettings.settings;
    translate.addLangs(['en','de','fr','ru','tr']);
    translate.setDefaultLang('en'); 
    translate.use('en');
  }
  Gettoken(){
    this.tokenService.loginValidate3(Global.Tokan_EndPoint + 'antiforgerytoken',this.value)
    .subscribe((response:any) => {
      
       let antiForgeryToken=response["antiForgeryToken"];
       this.wstoken=antiForgeryToken;
       localStorage.setItem('wstoken', this.wstoken);
       this.credentials[`UserName`]="Properties";
       this.credentials[`ApplicationId`]="Properties@123#";
       this.tokenService.loginValidate( Global.Tokan_EndPoint+ 'GetToken', this.credentials)
       .subscribe((response: any) => {
        
        if (response != undefined) {
            localStorage.setItem('currentUser', response.token);
          }
      }, (err: Response) => { console.log(err); console.log('Something went wrong!'); 
       });   
  });
  }
  ngAfterViewInit(){ 
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {   
    //     setTimeout(() => {
    //       if (isPlatformBrowser(this.platformId)) {
    //         window.scrollTo(0,0);
    //       }
    //     }); 
    //   }            
    // });    
  }

}
