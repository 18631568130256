import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PropertiesService } from 'src/app/services/properties.service';
import { TokenService } from 'src/app/services/Token.service';
import { AppService } from '../../app.service'; 
import { Global } from '../global';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-properties-search',
  templateUrl: './properties-search.component.html',
  styleUrls: ['./properties-search.component.scss']
})
export class PropertiesSearchComponent implements OnInit {
  @Input() variant:number = 1;
  @Input() vertical:boolean = false;
  @Input() searchOnBtnClick:boolean = true;
  @Input() removedSearchField:string;
  @Input() clearedSearchField:boolean=false;
  @Output() onSearchChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchClick: EventEmitter<any> = new EventEmitter<any>();
  public showMore: boolean = false;
  public form: UntypedFormGroup;
  public propertyTypes = [];
  public propertyStatuses = [];
  
   public cities = [];
  public neighborhoods = [];
  public streets = [];
  public features = [];
  public countries=[];
  public value: any;
  public wstoken:any;
  public credentials={};
  public country="";
  public city="";
  public querycountry:string="";
  public querytype:string="";
  public querystatus:[];
  public querycity:string="";
  public propertyStatus=[];
  public urlquery = "";
  constructor(public appService:AppService, public fb: UntypedFormBuilder,
    public propservices:PropertiesService,public tokenService: TokenService, 
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
    var flagid=localStorage.getItem("countryid");
    if(flagid!=undefined && flagid!=null){
      var cdata=flagid=="1"?"India":flagid=="2"?"singapore":flagid=="3"?"hong kong":"dubia";
      if(cdata != null){
        //this.countryurl(cdata);
      }
    }
      this.querycountry=params.country==undefined?null:params.country.toLowerCase();
      this.querytype=params.type==undefined?null:params.type.toLowerCase();
      //this.querystatus=params.status;
      this.querycity=params.city==undefined?null:params.city.toLowerCase();
      var splitdata=params.status==undefined?null:params.status.split(',')
       this.querystatus=splitdata;
      
    });
    this.getToken();
    this.getCountries();
    this.getpropertyTypes()
    if(this.vertical){
      this.showMore = true;
    };
    //this.propertyTypes = this.appService.getPropertyTypes();
    this.propertyStatuses = this.appService.getPropertyStatuses();
     this.cities = this.appService.getCities().filter(item => item.country == this.querycountry);
    this.neighborhoods = this.appService.getNeighborhoods();
    this.streets = this.appService.getStreets();
    this.features = this.appService.getFeatures();
    this.form = this.fb.group({
      propertyType: null,
      propertyStatus: null, 
      countryflag:null,
      price: this.fb.group({
        from: null,
        to: null 
      }),
      city: this.querycity,
      country:this.querycountry,
      zipCode: null,
      neighborhood: null,
      street: null,
      bedrooms: this.fb.group({
        from: null,
        to: null 
      }),
      bathrooms: this.fb.group({
        from: null,
        to: null 
      }),
      garages: this.fb.group({
        from: null,
        to: null 
      }),
      area: this.fb.group({
        from: null,
        to: null 
      }),
      yearBuilt: this.fb.group({
        from: null,
        to: null 
      }),       
      features: this.buildFeatures()
    }); 
    this.form.controls['propertyType'].setValue(this.querytype, {emitEvent: false});
    this.form.controls['propertyStatus'].setValue(this.querystatus, {emitEvent: false});
    this.onSearchClick.emit(this.form); 
    this.onSearchChange.emit(this.form);
  }
  // countryquery(){
  //   debugger;
  //   var country = this.form.controls['country'].value;
  //   if(country != null){
  //   if(window.location.href.includes("?") || this.urlquery.includes("?")){
  //     if(window.location.href.includes("?country=") || window.location.href.includes("&country=")
  //     || this.urlquery.includes("?country=") || this.urlquery.includes("&country=")){
  //       var currentUrl;
  //       if(window.location.href.split("?")[1].length == 0){
  //         currentUrl = new URL(window.location.href);
  //       }
  //       else{
  //         currentUrl = new URL(window.location.href.split("?")[0] + this.urlquery);
  //       }
  //       currentUrl.searchParams.set("country", country);
  //       var newUrl = currentUrl.search;
  //       //const name = currentUrl.searchParams.get('country');
  //       //alert(name);
  //      //var newUrl = "?country="+country;
  //       //window.location.href = newUrl;
  //       this.urlquery = newUrl;
  //     }
  //     else {
  //       //window.location.href = window.location.href.concat("&country="+country);
  //       this.urlquery = this.urlquery.concat("&country="+country);
  //     }
  //   }
  //   else{
  //     //window.location.href = window.location.href.concat("?country="+country);
  //     this.urlquery = this.urlquery.concat("?country="+country);
  //   }
  // }
  // else{
  //   this.urlquery = this.urlquery;
  // }
  // }
  // countryurl(country){
  //   if(window.location.href.includes("?")){
  //     if(window.location.href.includes("?country=") || window.location.href.includes("&country=")){
  //       var currentUrl =  new URL(window.location.href);
  //       currentUrl.searchParams.set("country", country);
  //       var newUrl = currentUrl.href;
  //       window.location.href = newUrl;
  //     }
  //     else {
  //       window.location.href = window.location.href.concat("&country="+country);
  //       //this.urlquery = this.urlquery.concat("&country="+country);
  //     }
  //   }
  //   else{
  //     window.location.href = window.location.href.concat("?country="+country);
  //     //this.urlquery = this.urlquery.concat("&country="+country);
  //   }
  // }
  propertytypequery(){
    var type =this.form.controls['propertyType'].getRawValue();
    if(type != null){
    if(window.location.href.includes("?") || this.urlquery.includes("?")){
      if(window.location.href.includes("?type=") || window.location.href.includes("&type=")
      || this.urlquery.includes("?type=") || this.urlquery.includes("&type=")){
        var currentUrl;
        if(window.location.href.split("?")[1].length == 0){
          currentUrl = new URL(window.location.href);
        }
        else{
          currentUrl = new URL(window.location.href.split("?")[0] + this.urlquery);
        }
        currentUrl.searchParams.set("type", type);
        var newUrl = currentUrl.search;
        //window.location.href = newUrl;
        // this.urlquery = "";
        //var newUrl = "?type="+type;
        this.urlquery = newUrl;
      }
      else {
        //window.location.href = window.location.href.concat("&type="+type);
        this.urlquery = this.urlquery.concat("&type="+type);
      }
    }
    else{
      //window.location.href = window.location.href.concat("?type="+type);
      this.urlquery = this.urlquery.concat("?type="+type);
    }
  }
  else{
    this.urlquery = this.urlquery;
  }
  }
  propertyStatusquery(){
    var status =this.form.controls['propertyStatus'].getRawValue();
    if(status != null && status.length > 0){
    status=status.join(',')
    //
    if(window.location.href.includes("?") || this.urlquery.includes("?")){
      if(window.location.href.includes("?status=") || window.location.href.includes("&status=") || this.urlquery.includes("?status=") || this.urlquery.includes("&status=")){
        var currentUrl;
        if(window.location.href.split("?")[1].length == 0){
          currentUrl = new URL(window.location.href);
        }
        else{
          currentUrl = new URL(window.location.href.split("?")[0] + this.urlquery);
        }
        currentUrl.searchParams.set("status", status);
        var newUrl = currentUrl.search;
        //window.location.href = newUrl;
        //this.urlquery = "";
        //var newUrl = "?status="+status;
        this.urlquery = newUrl;
      }
      else {
        //window.location.href = window.location.href.concat("&status="+status);
        this.urlquery = this.urlquery.concat("&status="+status);
      }
    }
    else{
      //window.location.href = window.location.href.concat("?status="+status);
      this.urlquery = this.urlquery.concat("?status="+status);
    }
  }
  else{
    this.urlquery = this.urlquery;
  }
  }
  getToken(){
    this.tokenService.loginValidate3(Global.Tokan_EndPoint + 'antiforgerytoken',this.value)
    .subscribe((response:any) => {
      
       let antiForgeryToken=response["antiForgeryToken"];
       this.wstoken=antiForgeryToken;
       localStorage.setItem('wstoken', this.wstoken);
       this.credentials[`UserName`]="Properties";
       this.credentials[`ApplicationId`]="Properties@123#";
       this.tokenService.loginValidate( Global.Tokan_EndPoint+ 'GetToken', this.credentials)
       .subscribe((response: any) => {
        
        if (response != undefined) {
            localStorage.setItem('currentUser', response);
            
          }
      }, (err: Response) => { console.log(err); console.log('Something went wrong!'); 
       });   
  });
  }
  getCountries(){
    
    this.propservices.post(Global.Home_EndPoint+ 'GetCountries', null).subscribe
    ((response: any) => {      
         
  this.countries=response;
  this.country="Singapore";
    },
    err => { console.log('Something went wrong!'); });
    
}
getpropertyTypes(){
  
  this.propservices.post(Global.Home_EndPoint+ 'GetPropertyType', null).subscribe
  ((response: any) => {   
this.propertyTypes=response;
  },
  err => { console.log('Something went wrong!'); });
  
}
  public buildFeatures() {
    const arr = this.features.map(feature => { 
      return this.fb.group({
        id: feature.id,
        name: feature.name,
        selected: feature.selected
      });
    })   
    return this.fb.array(arr);
  }
  

  ngOnChanges(){ 
    if(this.removedSearchField){ 
      if(this.removedSearchField.indexOf(".") > -1){
        let arr = this.removedSearchField.split(".");
        this.form.controls[arr[0]]['controls'][arr[1]].reset();
      } 
      else if(this.removedSearchField.indexOf(",") > -1){        
        let arr = this.removedSearchField.split(","); 
        this.form.controls[arr[0]]['controls'][arr[1]]['controls']['selected'].setValue(false);  
      }
      else{
        this.form.controls[this.removedSearchField].reset();
      }  
    }  
    if(this.clearedSearchField){
      this.reset();
      this.search();
    }
  }

  public reset(){     
    this.form.reset({ 
      propertyType: null,
      propertyStatus: null,
      country:"", 
    }); 
  }

  public search(){
var country=this.form.controls['country'].getRawValue();
var city=this.form.controls['city'].getRawValue();
var type =this.form.controls['propertyType'].getRawValue();
var status =this.form.controls['propertyStatus'].getRawValue();
    this.onSearchClick.emit(this.form); 
    //this.countryquery();
    //this.propertytypequery();
    //this.propertyStatusquery(); 
    var url =  window.location.origin;
    var totalurl="";
    if(country!=null && city ==null &&type==null &&status ==null){
      window.location.href =url+ "/citylist?country="+country;
    }else{ 
      if(country!=null){
      totalurl=url+"/properties?country="+country;
    }
    if(city !=null){
      if(totalurl!=""){totalurl=totalurl+"&city="+city}else{
        totalurl=url+"/properties?city="+city
      }}
    if(type!=null){if(totalurl!=""){totalurl=totalurl+"&type="+type}else{
      totalurl=url+"/properties?type="+type
    }}
    if(status!=null){if(totalurl!=""){totalurl=totalurl+"&status="+status}else{
      totalurl=url+"/properties?status="+status
    }}
    if(totalurl!=""){
      window.location.href=totalurl;
    }
    
  }
  }
  public onSelectCountry(data: any){
    this.cities = this.appService.getCities().filter(item => item.country == data.value);
  }
  public onSelectCity(){
    this.form.controls['neighborhood'].setValue(null, {emitEvent: false});
    this.form.controls['street'].setValue(null, {emitEvent: false});
  }
  public onSelectNeighborhood(){
    this.form.controls['street'].setValue(null, {emitEvent: false});
  }

  public getAppearance(){
    return (this.variant != 3) ? 'outline' : '';
  }
  public getFloatLabel(){
    return (this.variant == 1) ? 'always' : '';
  }
 

}
