import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser'
import { AppService } from 'src/app/app.service';
import { PropertiesService } from 'src/app/services/properties.service';
import { Settings, AppSettings } from '../../app.settings';
import { Global } from '../global';

@Component({
  selector: 'app-header-image',
  templateUrl: './header-image.component.html',
  styleUrls: ['./header-image.component.scss']
})
export class HeaderImageComponent implements OnInit {
  @Input('backgroundImage') backgroundImage;
  @Input('bgImageAnimate') bgImageAnimate;
  @Input('contentOffsetToTop') contentOffsetToTop;
  @Input('contentMinHeight') contentMinHeight;
  @Input('title') title;
  @Input('desc') desc;
  @Input('isHomePage') isHomePage:boolean = false;
  @Input('fullscreen') fullscreen: boolean = false; 
  public bgImage;
  public settings: Settings; 
  constructor(public appSettings:AppSettings, private sanitizer:DomSanitizer,public propservices:PropertiesService,public appservices: AppService) {
    this.settings = this.appSettings.settings;
    setTimeout(() => {
      this.settings.headerBgImage = false;
    }); 
  }

  ngOnInit() {
    if(this.contentOffsetToTop){
      setTimeout(() => {
        this.settings.contentOffsetToTop = this.contentOffsetToTop;
      }); 
    } 
    if(this.backgroundImage){
      if(this.title=="Contact Us"){
        this.propservices.post(Global.Home_EndPoint + 'ContactUsDetails', null).subscribe((response: any) => {
          this.backgroundImage=response.backgroundImage;
          
          this.appservices.addTag("Contact Us","Content title for contact us","Contact Us","Contact Us",this.backgroundImage);
          this.bgImage = this.sanitizer.bypassSecurityTrustStyle('url('+response.backgroundImage +')'); 
        });
      }else if(this.title=="About Us"){
        this.propservices.post(Global.Home_EndPoint + 'OurServicesDetails', null).subscribe((response: any) => {
          this.backgroundImage=response.backgroundImage;
          this.appservices.addTag("About Us","Content Title for about us","Banner,Our Story,Our Services,Agents,Get in Touch ","About Us",this.backgroundImage);
          this.bgImage = this.sanitizer.bypassSecurityTrustStyle('url('+response.backgroundImage +')'); 
        });
      }
        else{
        this.bgImage = this.sanitizer.bypassSecurityTrustStyle('url('+this.backgroundImage +')'); 
      }
        
      }
  }

  ngOnDestroy(){    
    setTimeout(() => {
      this.settings.headerBgImage = false; 
      this.settings.contentOffsetToTop = false;
    });  
  }

}
