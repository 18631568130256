import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppService } from 'src/app/app.service';
import { PropertiesService } from 'src/app/services/properties.service';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { Global } from '../global';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  testimonalListLink = "http://www.sworldproperties.com/testmonialslist";
  @Input('propertyId') propertyId;
  public commentForm: UntypedFormGroup;
  public commentsTitle:string="Leave a Reply";
  public isloadmore:boolean=false;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  public reviews =[];
  // public reviews = [
  //   { 
  //     author: 'Bruno Vespa', 
  //     avatar: 'assets/images/avatars/avatar-1.png', 
  //     tooltip: 'Dissatisfied', 
  //     icon: 'sentiment_dissatisfied',
  //     date: '13 January, 2018 at 7:09',
  //     text: 'Integer id eros et mi fringilla imperdiet. In dictum turpis eget magna viverra condimentum. Ut malesuada interdum ultrices. Proin tristique sem pellentesque, posuere dui in, maximus magna. Aenean vehicula, tortor gravida elementum tincidunt, justo lorem vestibulum ex, eget egestas arcu tellus in magna.'
  //   },
  //   { 
  //     author: 'Julia Aniston', 
  //     avatar: 'assets/images/avatars/avatar-2.png', 
  //     tooltip: 'Very Satisfied', 
  //     icon: 'sentiment_very_satisfied',
  //     date: '04 February, 2018 at 10:22',
  //     text: 'Nulla accumsan, lacus sed suscipit rutrum, turpis augue accumsan metus, in accumsan urna mi vehicula lorem. Pellentesque semper nibh vitae augue placerat finibus. Nulla sed porttitor nunc, quis tristique sem. Quisque in varius nisl. Integer turpis lorem, ultricies sed sem nec, commodo molestie arcu. Nulla finibus ex tortor, et suscipit magna semper consectetur. Cras sit amet metus dui. Maecenas eget dui at ex varius malesuada vel non felis.'
  //   },
  //   { 
  //     author: 'Andy Warhol', 
  //     avatar: 'assets/images/avatars/avatar-3.png', 
  //     tooltip: 'Neutral', 
  //     icon: 'sentiment_neutral',
  //     date: '14 February, 2018 at 11:10',
  //     text: 'Pellentesque hendrerit vel turpis aliquam placerat. Suspendisse ullamcorper congue feugiat. Etiam gravida metus ac massa posuere venenatis. Pellentesque vehicula lobortis dolor, ac pretium dolor maximus quis. Fusce vitae iaculis mauris, quis posuere ex. Mauris vitae convallis nibh. Etiam eget enim at orci interdum maximus nec in ante.'
  //   }
  // ];
  public ratings = [
    { title: 'Very Dissatisfied', icon: 'sentiment_very_dissatisfied', percentage: 1, selected: false },
    { title: 'Dissatisfied', icon: 'sentiment_dissatisfied', percentage: 2, selected: false },
    { title: 'Neutral', icon: 'sentiment_neutral', percentage: 3, selected: false },
    { title: 'Satisfied', icon: 'sentiment_satisfied', percentage: 4, selected: false },
    { title: 'Very Satisfied', icon: 'sentiment_very_satisfied', percentage: 5, selected: false }
  ];
  public deviceInfo:any;
  constructor(public fb: UntypedFormBuilder,public propservices:PropertiesService, public snackBar: MatSnackBar
    , private deviceService: DeviceDetectorService,public appService:AppService,private activatedRoute: ActivatedRoute,  ) { 
      this.deviceInfo = this.deviceService.getDeviceInfo();  
      //var data=this.propertyId;
      if(this.propertyId==undefined){
        this.propertyId=0;
      }
      this.activatedRoute.params.subscribe(params => { 
        if(!params['id']){
          this.commentsTitle="TESTMONIALS";
          this.isloadmore=true;
        }
    });
    }
    show(value :any){
      if(!($(".showicon.icon" + value).hasClass("showicon"))){
        $(".icon" + value).addClass("showicon");
      }
      else{
        $(".showicon.icon" + value).removeClass("showicon");
      }
    }
    whatsapp(id:any, name:any, description:any){
      const link = encodeURI(this.testimonalListLink);
      //const msg = encodeURIComponent('Hey, I found this article');
    //  const location = `https://web.whatsapp.com/send?text=${name}\n${description}\n${link}#${id}`;
      var location = '';
    //const msg = encodeURIComponent('Hey, I found this article');
      var width = $(window).width();
      if (width <= 480) {
        location = `whatsapp://send?text=${name}\n${description}\n${link}#${id}`;
      } else {
        location = `https://web.whatsapp.com/send?text=${name}\n${description}\n${link}#${id}`;
      }
      $(".whatsapp").attr("href",encodeURI(location));
    }
    facebook(id:any, name:any, description:any){
      const link = encodeURI(this.testimonalListLink);
      const location = `https://www.facebook.com/share.php?u=${name}\n${description}\n${link}#${id}`;
      $(".facebook").attr("href",encodeURI(location));
    }
    twitter(id:any, name:any, description:any){
      const link = encodeURI(this.testimonalListLink);
      // const msg = encodeURIComponent('Hey, I found this article');
      // this.testimonalListLink = `http://twitter.com/share?&url=${name}\n${description}\n${link}&text=${msg}&hashtags=javascript,programming`;
      const location = `http://twitter.com/share?&url=${name}\n${description}\n${link}#${id}&hashtags=SWorldProperites`;
      $(".twitter").attr("href",encodeURI(location));
      }
    telegram(id:any, name:any, description:any){
      const link = encodeURI(this.testimonalListLink);
      //const msg = encodeURIComponent('Hey, I found this article');
      const location = `https://telegram.me/share/url?url=${name}\n${description}\n${link}#${id}`;
      $(".telegram").attr("href",encodeURI(location));
    }
    linkedin(id:any, name:any, description:any){
      const link = encodeURI(this.testimonalListLink);
      const location=`https://www.linkedin.com/sharing/share-offsite/?url=${name}\n${description}\n${link}#${id}`;
      $(".linkedin").attr("href",encodeURI(location));
    }
  ngOnInit() {
    this.commentForm = this.fb.group({ 
      review: [null, Validators.required],            
      name: [null, Validators.compose([Validators.required, Validators.minLength(4)])],
      email: [null, Validators.compose([Validators.required, emailValidator])],
      rate: null,
    }); 
    this.GetReviewList(this.propertyId)
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
    var id = window.location.href.split("#");
    const targetElement = document.getElementById(id[id.length - 1]);
 
                if (targetElement) {
                    const offsetTop = targetElement.offsetTop + 100;
                    window.scroll({
                        top: offsetTop,
                        behavior: 'smooth'
                    });
                }
     }, 500);
    
  }
  GetReviewList(prop){
    this.propservices.post(Global.Home_EndPoint + 'GetReviewList', prop).subscribe((response: any) => {
      
   this.reviews=response;
    });     
  }
  public onCommentFormSubmit(values:any){    
    values.propertyId= this.propertyId
    if (this.commentForm.valid) { 
      this.propservices.post(Global.Home_EndPoint + 'AddLeaveReview', values).subscribe((response: any) => {
        if(response==true){ 
          this.appService.GetIpAddress(this.deviceInfo,"Review",values[`propertyId`],"rating");  
          this.GetReviewList(values[`propertyId`]);
            this.snackBar.open('Your review submited successfully!', '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
            this.formGroupDirective.resetForm();
        }else{
            this.snackBar.open('Your review submited faild. Please try again!', '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
        }
                });
      if(values.rate){
        //property.ratingsCount++,
        //property.ratingsValue = property.ratingsValue + values.rate,
      }     
    } 
  }

  public rate(rating:any){
    this.ratings.filter(r => r.selected = false);
    this.ratings.filter(r => r.percentage == rating.percentage)[0].selected = true;
    this.commentForm.controls.rate.setValue(rating.percentage);
  }

}
