import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TokenService } from 'src/app/services/Token.service';
import { PropertiesService } from 'src/app/services/properties.service';
import { Global } from 'src/app/shared/global';

@Component({
  selector: 'app-testmonialdetails',
  templateUrl: './testmonialdetails.component.html',
  styleUrls: ['./testmonialdetails.component.scss']
})
export class TestmonialdetailsComponent implements OnInit {
  public testimonials;
  public videoLink;
  public reviews =[];
  testimonalListLink = "/testmonialslist";
  constructor(public tokenservices:TokenService,public propservices:PropertiesService, public activatedRoute:ActivatedRoute) { }

  ngOnInit(){
    this.getTestimonials(); 
    this.GetReviewList(0);
    this.activatedRoute.fragment.subscribe(fragment => {
      console.log("fragment: "+fragment)
      if (fragment) {
        // Scroll to the element with the corresponding ID
        const element = document.getElementById(fragment);
        if (element) {
          //element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          const offsetTop = element.offsetTop + 200;
                    window.scroll({
                        top: offsetTop,
                        behavior: 'smooth'
                    });
        }
      }
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
    var id = window.location.href.split("#");
    const targetElement = document.getElementById(id[id.length - 1]);
 
                if (targetElement) {
                    const offsetTop = targetElement.offsetTop + 200;
                    window.scroll({
                        top: offsetTop,
                        behavior: 'smooth'
                    });
                }
     }, 500);
    
  }
  convert(){
    
  }
  getTestimonials(){
    this.tokenservices.loginValidate3(Global.Tokan_EndPoint+ 'GetTestimonials', null).subscribe
    ((response: any) => {      
         
  this.testimonials=response;
    },
    err => { console.log('Something went wrong!'); });
  }
  show(value :any){
    if(!($(".showicon.icon" + value).hasClass("showicon"))){
      $(".icon" + value).addClass("showicon");
    }
    else{
      $(".showicon.icon" + value).removeClass("showicon");
    }
  }
  whatsapp(id:any, name:any, description:any){
    const link = window.location.origin + encodeURI(this.testimonalListLink);
    var location = '';
    //const msg = encodeURIComponent('Hey, I found this article');
    var width = $(window).width();
    if (width <= 480) {
      location = `whatsapp://send?text=${name}\n${description}\n${link}#${id}`;
    } else {
      location = `https://web.whatsapp.com/send?text=${name}\n${description}\n${link}#${id}`;
    }
    $(".whatsapp").attr("href",encodeURI(location));
  }
  facebook(id:any, name:any, description:any){
    const link = window.location.origin + encodeURI(this.testimonalListLink);
    const location = `https://www.facebook.com/share.php?u=${name}\n${description}\n${link}#${id}`;
    $(".facebook").attr("href",encodeURI(location));
  }
  twitter(id:any, name:any, description:any){
    const link = window.location.origin + encodeURI(this.testimonalListLink);
    // const msg = encodeURIComponent('Hey, I found this article');
    // this.testimonalListLink = `http://twitter.com/share?&url=${link}&text=${msg}&hashtags=javascript,programming`;
    const location = `http://twitter.com/share?&url=${name}\n${description}\n${link}#${id}&hashtags=SWorldProperites`;
    $(".twitter").attr("href",encodeURI(location));
    }
  telegram(id:any, name:any, description:any){
    const link = window.location.origin + encodeURI(this.testimonalListLink);
    //const msg = encodeURIComponent('Hey, I found this article');
    const location = `https://telegram.me/share/url?url=${name}\n${description}\n${link}#${id}`;
    $(".telegram").attr("href",encodeURI(location));
  }
  linkedin(id:any, name:any, description:any){
    const link = window.location.origin + encodeURI(this.testimonalListLink);
    const location=`https://www.linkedin.com/sharing/share-offsite/?url=${name}\n${description}\n${link}#${id}`;
    $(".linkedin").attr("href",encodeURI(location));
  }
  getimagelink(url:string) {
    var splitcount=url.split("/");
    return "https://i.ytimg.com/vi/"+splitcount[(splitcount.length-1)]+"/hqdefault.jpg";
      }
      VideoItem(url:string){
        window.open(url, "_blank");
      }
      GetReviewList(prop){
        this.propservices.post(Global.Home_EndPoint + 'GetAllReviewList', prop).subscribe((response: any) => {
       this.reviews=response;
        });     
      }
}
