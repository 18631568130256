import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Property } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-properties-countryflag',
  templateUrl: './properties-countryflag.component.html',
  styleUrls: ['./properties-countryflag.component.scss']
})
export class PropertiesCountryflagComponent implements OnInit {
  public properties: Property[];
  @Input() variant:number = 1;
  @Input() searchOnBtnClick:boolean = true;
  public form: UntypedFormGroup;
  public btnsingclass:string="";
  public btnindclass:string="";
  public btnhongclass:string="";
  public btndubaiclass:string="";
  public indiacount:number=0;
  public singaporecount:number=0;
  public dubaicount:number=0;
  public Hongkongcount:number=0;
  @Output() onSearchChangeflag: EventEmitter<any> = new EventEmitter<any>();
  @Input() removedSearchFieldFlag:boolean=false;
  constructor( public fb: UntypedFormBuilder,public appService:AppService, private router: Router) {this.getProperties(); }

  ngOnInit(): void {
    
    this.form = this.fb.group({
      countryflag:null
    }); 

  }
  public getProperties(){   
    this.appService.getProperties().subscribe(data => { 
      this.indiacount = data.filter(property => property.country == 'India').length;
      this.singaporecount =data.filter(property => property.country == 'Singapore').length;
      this.Hongkongcount =data.filter(property => property.country == 'Hong Kong').length;
      this.dubaicount =data.filter(property => property.country == 'Dubia').length;
    })
  }
  public getAppearance(){
    return (this.variant != 3) ? 'outline' : '';
  }
  public getFloatLabel(){
    return (this.variant == 1) ? 'always' : '';
  }
  public singaporeimg(){
    localStorage.setItem('countryid',"2");
    var url = window.location.href.split("?")[0];
    window.location.href = url + "/citylist?country=singapore";
  //   this.btndubaiclass="";
  //   this.btnindclass="";
  //   this.btnhongclass="";
  //  this.btnsingclass="activeborder";
   
  //  this.onSearchChangeflag.emit("Singapore");
  }
  public indimg(){
    localStorage.setItem('countryid',"1");
    var url = window.location.href.split("?")[0];
    window.location.href = url + "/citylist?country=India";
    this.router.navigateByUrl('citylist');
  //   this.btnsingclass="";
  //   this.btnhongclass="";
  //   this.btndubaiclass="";
  //  this.btnindclass="activeborder";
  //  this.onSearchChangeflag.emit("India");
  }
  public hongimg(){
    localStorage.setItem('countryid',"3");
    var url = window.location.href.split("?")[0];
    window.location.href = url + "/citylist?country=hong kong";
    this.router.navigateByUrl('citylist');
    //this.router.navigateByUrl('properties');
  //   this.btnsingclass="";
  //   this.btnindclass="";
  //   this.btndubaiclass="";
  //  this.btnhongclass="activeborder";
  //  this.onSearchChangeflag.emit("Hong Kong");
  }
  public dubaiimg(){
    localStorage.setItem('countryid',"4");
    var url = window.location.href.split("?")[0];
    window.location.href = url + "/citylist?country=dubia";
     //this.router.navigateByUrl('citylist');
  //   this.btnsingclass="";
  //   this.btnindclass="";
  //   this.btnhongclass="";
  //  this.btndubaiclass="activeborder";
  //  this.onSearchChangeflag.emit("Dubia");
  }
  ngOnChanges(){
    
    if(this.removedSearchFieldFlag){
      this.btnsingclass="";
      this.btnindclass="";
      this.btnhongclass="";
      this.btndubaiclass="";
      this.removedSearchFieldFlag=false;
    }
   }
}
