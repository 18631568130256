import { Component, OnInit } from '@angular/core';
import { PropertiesService } from 'src/app/services/properties.service';
import { Global } from '../global';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit {
public OurServices:string="";
  constructor(public propservices:PropertiesService) {
    this.Getourservices();
   }

  ngOnInit() {

  }
Getourservices(){
  this.propservices.post(Global.Home_EndPoint + 'OurServicesDetails', null).subscribe((response: any) => {
     
    this.OurServices=response.ourServices;
  });
}
}
