import { Component, OnInit, Input, ViewChild, HostListener } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  providers: [ MenuService ]
})
export class VerticalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId;
  @ViewChild('sidenav') sidenav:any; 
  public sidenavOpen:boolean = true;
  public menuItems: Array<Menu>;
  constructor(public menuService:MenuService) { }

  ngOnInit() {
    this.menuItems = this.menuService.getVerticalMenuItems();
    this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId);
    if(window.innerWidth < 960){
      this.sidenavOpen = false;
      if(this.sidenav){
        this.sidenav.close();
      } 
    };
  }
  @HostListener('window:resize')
  public onWindowResize():void {
    (window.innerWidth < 960) ? this.sidenavOpen = false : this.sidenavOpen = true; 
  }
  onClick(menuId, href){
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuService.getVerticalMenuItems(), menuId);   
    if(href.includes('#')){
      const element = document.querySelector("#testimonial_scroll")
    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } 
  }

}
