import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { TokenService } from './Token.service';

@Injectable()
export class PropertiesService {

  constructor(private http: HttpClient, public router: Router,
     private tokanService: TokenService) { }

     TokenExpirition() {
      let that = this;
        localStorage.removeItem('currentUser');
        sessionStorage.clear();
        localStorage.clear();
       // that.router.navigate([``]);
    }
    
  post(url: string, model: any, header?: Headers) {
    
    if (this.tokanService.isLoggedin() === false) {
      this.TokenExpirition();
    }

    let body = JSON.stringify(model);
    const headers: HttpHeaders= new HttpHeaders()
    .append('Content-Type',  'application/json');
    const options = {
        headers: headers
      };
    // const headers: HttpHeaders = new HttpHeaders()
    //   .append('Content-Type', 'application/json')
    //   .append('Authorization', 'Bearer ' + this.tokanService.token())
    //   .append('XSRF-TOKEN', localStorage.getItem('wstoken'));
    // const options = {
    //   headers: headers
    // };
    return this.http.post(url, body, options).pipe(map((response: Response) => response), catchError((response, caught) => {
      if (response.status === 0 || response.status === 401) {
        localStorage.removeItem('currentUser');
        this.router.navigate([`#`]);
      }
      return  Promise.reject(response.message || response);
    }))
  };
}

