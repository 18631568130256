import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { PropertiesService } from 'src/app/services/properties.service';
import { Global } from 'src/app/shared/global';
import { emailValidator, mobileValidator } from '../../utils/app-validators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
@ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  public lat: number = 40.678178;
  public lng: number = -73.944158;
  public zoom: number = 12; 
  public mapStyles:any = [
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#000000"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#000000"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#8b9198"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#323336"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#414954"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#2e2f31"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#7a7c80"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#242427"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#202022"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#393a3f"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#202022"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#393a3f"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#202022"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#202124"
            }
        ]
    }
  ];
  public feedbackForm: UntypedFormGroup;
  public subscribeForm: UntypedFormGroup;
  public deviceInfo:any;
  constructor(public formBuilder: UntypedFormBuilder,public propservices:PropertiesService, public snackBar: MatSnackBar,
    private deviceService: DeviceDetectorService,public appservices: AppService) { 
        this.deviceInfo = this.deviceService.getDeviceInfo();
    }

  ngOnInit() {
    this.feedbackForm = this.formBuilder.group({ 
      email: ['', Validators.compose([Validators.required, emailValidator])], 
      mobilenumber:['',Validators.compose([Validators.required,mobileValidator])],
      message: ['', Validators.required]
    });
    this.subscribeForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, emailValidator])],
      
    })
  }

  public onSubscribeFormSubmit(values:Object):void {
   
    if (this.feedbackForm.valid) {
        this.propservices.post(Global.Home_EndPoint + 'AddSubscription', values).subscribe((response: any) => {
if(response==true){ 
    this.appservices.GetIpAddress(this.deviceInfo,"subscribe",0,"subscribe");  
    this.snackBar.open('Subscribe details submited successfully!', '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
    this.formGroupDirective.resetForm();
    //this.feedbackForm.reset();
}else{
    this.snackBar.open('Subscribe details submited faild. Please try again!', '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
}
        });
    }
  }
  
//   public onSubscribeFormSubmit(values:Object):void {
//     if (this.subscribeForm.valid) {
//       console.log(values);
//     }
//   }

whatsapp(){
    const link = encodeURIComponent(window.location.href);
    var location = '';
    var width = $(window).width();
    if (width <= 480) {
      location = `whatsapp://send?text=${link}`;
    } else {
      location = `https://web.whatsapp.com/send?text=${link}`;
    }
    $(".whatsapp").attr("href",location);
  }
  facebook(){
    const link = encodeURIComponent(window.location.href);
    const location = `https://www.facebook.com/share.php?u=${link}`;
    $(".facebook").attr("href",location);
  }
  twitter(){
    const link = encodeURIComponent(window.location.href);
    const location = `http://twitter.com/share?&url=${link}&hashtags=SWorldProperites`;
    $(".twitter").attr("href",location);
    }
  telegram(){
    const link = encodeURIComponent(window.location.href);
    const location = `https://telegram.me/share/url?url=${link}`;
    $(".telegram").attr("href",location);
  }
  linkedin(){
    const link = encodeURIComponent(window.location.href);
    const location=`https://www.linkedin.com/sharing/share-offsite/?url=${link}`;
    $(".linkedin").attr("href",location);
  }
}
